#servicos {
  padding: 2rem 0;
  position: relative;
  z-index: 1; /* Garante que a seção esteja acima de outros elementos, se necessário */
}

.bg-white {
  background-color: #ffffff;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem; /* Ajuste conforme necessário */
}

.font-mono {
  font-family: monospace;
}

.carousel-wrapper {
  position: relative;
  width: 50vw;
  height: 500px; /* Ajuste a altura conforme necessário */
  overflow: hidden; /* Evita que o carrossel se estenda fora do contêiner */
  margin-left: 25vw;
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #624ec0; /* Cor de fundo mais escura */
  color: white;
  border: none;
  border-radius: 50%;
  width: 3rem; /* Ajuste o tamanho do botão conforme necessário */
  height: 3rem; /* Ajuste o tamanho do botão conforme necessário */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 10; /* Garante que os botões estejam acima do carrossel */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona sombra ao botão */
  transition: background-color 0.3s, transform 0.3s; /* Transições suaves */
}

.carousel-button:hover {
  background-color: #3d16e7; /* Escurece ao passar o mouse */
  transform: translateY(-50%) scale(1.1); /* Aumenta o botão ao passar o mouse */
}

.prev-button {
  left: 10px; /* Ajuste a distância da margem esquerda */
}

.next-button {
  right: 10px; /* Ajuste a distância da margem direita */
}

@media (max-width: 768px) {
  .carousel-wrapper {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
