.menu-sidebar {
  transition: transform 0.5s ease-in-out;
  height: auto; /* Ajusta a altura ao conteúdo */
  max-height: 90vh; /* Define uma altura máxima para evitar que o menu seja muito alto */
  overflow-y: auto; /* Adiciona rolagem se o conteúdo exceder a altura máxima */
  z-index: 50;
  background-color: #f3f0ff;
  border-radius: 3%;
}

.menu-sidebar-open {
  transform: translateX(0);
}

.menu-sidebar-closed {
  transform: translateX(100%);
}
