.card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  justify-content: center;
  background-color: #624ec0;
  width: 16rem; /* Largura fixa para telas maiores */
  height: auto; /* Ajusta a altura conforme o conteúdo */
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona sombra para profundidade */
  margin: 0 0.5rem; /* Adiciona espaço entre as cartas */
  transition: transform 0.3s ease-in-out; /* Suaviza o efeito ao rolar */
}

.card img {
  margin-top: -20%; /* Ajuste para a imagem se sobrepor ao topo */
  width: 100%;
  border-radius: 20px;
  object-fit: cover; /* Garante que a imagem cubra a área sem distorção */
}

.card h2 {
  margin: 0;
  margin-top: 1rem;
  color: white;
  font-size: large;
  font-family: monospace;
  text-align: center; /* Centraliza o texto */
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  color: white;
  text-align: center; /* Centraliza o texto */
}

/* Ajustes para dispositivos móveis */
@media (max-width: 768px) {
  .card {
    width: 14rem; /* Ajusta a largura para telas menores */
    padding: 0 1rem 1rem 1rem; /* Ajusta o padding */
  }
}

@media (max-width: 480px) {
  .card {
    width: 12rem; /* Ajusta a largura para dispositivos muito pequenos */
    padding: 0 0.5rem 0.5rem 0.5rem; /* Ajusta o padding */
  }
}
